import React from "react"
import Body, { BodyProps } from "../typography/Body"
import useBrowserLayoutEffect from "../../hooks/useBrowserLayoutEffect"
import "../../vendor/affirm"

type AffirmWidgetProps = BodyProps & {
  amount: number
  pageType: "product" | "cart"
}

export default function AffirmWidget({
  pageType,
  amount,
  ...props
}: AffirmWidgetProps) {
  useBrowserLayoutEffect(() => {
    if (amount !== 0) {
      // @ts-ignore
      const affirm = window.affirm
      affirm?.ui?.ready(function () {
        affirm.ui.refresh()
      })
    }
  }, [amount])

  return (
    <Body
      as="p"
      size="sm"
      {...props}
      className="affirm-as-low-as"
      data-page-type={pageType}
      data-amount={amount * 100}
    />
  )
}
